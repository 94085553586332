import React, { useState, useRef } from 'react';
import { apiClient } from './../../utils/apiClient';
import { Col, Row } from 'antd';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { TestScoreCodeMapping, TestScoreDateMapping } from '../../components-v2/TestScoreDefineCodes';
import { dataActions } from '../../redux/store';
import { ApiClientType, CUSTOM_FORMAT } from '../../utils/constants';
import { featureFlags } from '../../utils/featureFlags';
interface TestScoreDefineCodesProps extends WizardProps {
  allFieldsRequired: boolean;
}

export interface DataTypeField {
  id: string;
  name: string;
  groupingId: string;
  dataTypeId: string;
  inputType: string;
  required: boolean;
  columnInFile: string;
  description?: string;
  dateFormat?: string;
  validationType?: string;
  maxCharacter?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  acceptableValues?: object;
  range?: {
    min: number;
    max: number;
  };
  CT?: string;
  CB?: string;
  MD?: string;
  MB?: string;
}

interface FieldCodeProps {
  fieldName: string;
  fieldMapping: FieldMapProps[];
  fieldId: string;
}

interface FieldMapProps {
  description: string;
  code: string | number;
}

interface DateFieldProps {
  mappingType: string;
  dateFormat: string;
  fieldId: string;
}

const TSDefineCodes = (props: TestScoreDefineCodesProps): React.ReactElement => {
  const dataTypeId = useSelector((state: RootStateOrAny) => state.dataTypeId);
  const headers = useSelector((state: RootStateOrAny) => state.fieldsData);
  const fields = useSelector((state: RootStateOrAny) => state.fieldsData);
  const config = useSelector((state: RootStateOrAny) => state.modifiedConfig);
  const dataTypeFormat = useSelector((state: RootStateOrAny) => state.dataTypeFormat);
  const uniqueIdentifierKey = useSelector((state: RootStateOrAny) => state.uniqueIdentifierKey);
  const checkEmptyNavianceField = useSelector((state: RootStateOrAny) => state.checkEmptyNavianceField);
  const [defineCodes, setDefineCodes] = useState([]);
  const [dateDefineCodes, setDateDefineCodes] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const dateFields = {};
  const dataTypeFields = async () => {
    let fieldCodeMappings: FieldCodeProps[] = [];
    let dateFieldMappings: DateFieldProps[] = [];
    if (fields.length) {
      fields.forEach((field: DataTypeField) => {
        if (
          config?.validateConfig.columns[field.id] &&
          field.validationType === 'AcceptableValues' &&
          field.acceptableValues
        ) {
          let fieldCodeMapping: FieldCodeProps = {
            fieldMapping: [],
            fieldId: '',
            fieldName: '',
          };
          fieldCodeMapping.fieldName = field.name;
          for (const [key, value] of Object.entries(field.acceptableValues)) {
            fieldCodeMapping.fieldMapping.push({ description: key, code: value });
          }
          fieldCodeMapping.fieldId = field.id;
          fieldCodeMappings.push(fieldCodeMapping);
        }

        if (config?.validateConfig.columns[field.id] && (field.inputType === 'Date' || field.dateFormat)) {
          let dateFieldMapping: DateFieldProps = {
            mappingType: '',
            dateFormat: '',
            fieldId: '',
          };
          dateFieldMapping.mappingType = field.name;
          dateFieldMapping.fieldId = field.id;
          dateFields[field.id] = field.dateFormat;
          dateFieldMappings.push(dateFieldMapping);
        }
      });
      dispatch(dataActions.addDateFields(dateFields));
      setDateDefineCodes(dateFieldMappings);
      setDefineCodes(fieldCodeMappings);
    }
  };

  const save = async (saveAndContinue?: boolean) => {
    try {
      await saveConfig();
      if (saveAndContinue) {
        props.continueFn();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
    }
  };

  const saveConfig = async () => {
    const uniqueField = headers.find((obj) => obj.isUnique);
    const uniqueFieldName = uniqueField ? uniqueField.id : '';
    setLoading(true);
    await apiClient(
      {
        url: `/testscore/datatype/${dataTypeId}/parameter-group?uniqueIdentifierKey=${uniqueIdentifierKey}&uniqueField=${uniqueFieldName}`,
        method: 'patch',
        data: config,
      },
      ApiClientType.DATA_INGEST_TOOL,
    );
    setLoading(false);
  };

  const cancel = (cancelAndBacktrack?: boolean) => {
    if (cancelAndBacktrack) {
      props.previousFn();
    } else {
      props.cancelFn();
    }
  };
  const changeStep = (newStep: number) => {
    props.changeStepFn(newStep);
  };

  React.useEffect(() => {
    dataTypeFields();
  }, []);

  const addMMMDateFormat = featureFlags['feature.dataIngestNew.addMMMDateFormat'];

  const mainContent = (
    <div className="subSections">
      <Row justify="space-between" align="bottom" style={{ margin: '8px 0px' }}>
        <Col>
          {defineCodes.length > 0 || dateDefineCodes.length > 0 ? (
            dataTypeFormat === CUSTOM_FORMAT || (addMMMDateFormat && dateDefineCodes.length > 0) ? (
              <span className="subsection-file-details">
                Review the list of possible values for each field and indicate how these values are presented in your
                data.
              </span>
            ) : (
              <span className="subsection-file-details">
                There are no codes to define for the official format of your file.
              </span>
            )
          ) : (
            <span className="subsection-file-details">There are no codes to define for this import</span>
          )}
        </Col>
      </Row>
      {defineCodes.length
        ? defineCodes.map((codes) => {
            return (
              <TestScoreCodeMapping
                fieldName={codes.fieldName}
                fieldMapping={codes.fieldMapping}
                fieldId={codes.fieldId}
                dataTypeFormat={dataTypeFormat}
              ></TestScoreCodeMapping>
            );
          })
        : ''}

      {dateDefineCodes.length
        ? dateDefineCodes.map((codes) => {
            return (
              <TestScoreDateMapping
                mappingType={codes.mappingType}
                dataTypeFormat={addMMMDateFormat ? CUSTOM_FORMAT : dataTypeFormat}
                mappingId={codes.fieldId}
                fieldsData={fields}
                config={config}
              ></TestScoreDateMapping>
            );
          })
        : ''}
    </div>
  );

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => {
        if (checkEmptyNavianceField) {
          showNotification(NotificationTypes.error, 'Cannot leave any File Code blank', '');
        } else {
          save(true);
        }
      }}
      previousFn={() => {
        if (checkEmptyNavianceField) {
          showNotification(NotificationTypes.error, 'Cannot leave any File Code blank', '');
        } else {
          cancel(true);
        }
      }}
      cancelFn={() => cancel()}
      changeStepFn={changeStep}
      hideHeaderButtons={true}
      pageLoading={loading}
    >
      {mainContent}
    </Wizard>
  );
};

export default TSDefineCodes;
